.membershipSection{
	padding: 50px 0 50px 0;
}
.membershipSection p{
	font-size: 16px;
	line-height: 25px;
	color: #333;
	margin: 0 0 20px 0;
}
.membershipPlans{padding: 0 0;}
.membershipPlans table{
	margin: 20px 0;
	width: 100%;
}
.membershipPlans table tr th{
	font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    border: 1px solid #3b822a;
    color: #fff;
    padding: 10px;
    background: #5aa846;
}
.membershipPlans table tr td{
	font-size: 16px;
	line-height: 22px;
	border: 1px solid #ccc;
	color: #333;
    padding: 10px;
}
.membershipPlans table tr td svg.tick .a{
	fill: #3b822a;
}
.membershipPlans table tr td svg.cross .a{
	fill: #f00;
} 
.newup{
	position: absolute;
	right: 20px;
    top: 17px; 
    border-radius: 5px;
    text-transform: capitalize;
    transform: rotate(30deg);
    background: #f00;
    color: #fff;
    display: inline-block;
    padding: 5px 15px;
    font-weight: 600;
}
.planDtls{
	padding: 30px 0;
}
.planDtlsInner ul{
	list-style: none;
	padding: 0;
	margin: 0;
	display: table;
	max-width: 75%;
    margin:0 auto 20px;
}
.planDtlsInner h4{ font-weight: bold; }
.planDtlsInner ul li,.cmn1 li,
.main ul li{
	display: block;
	padding: 8px 0;
	font-size: 15px;
	line-height: 22px;
	color: #000;
	font-weight: 600;
	position: relative;
}
.eventSection .cmn1 li a{display: inline-block; color: #5fab4c;}
.planDtlsInner ul li:before,.cmn1 li:before,
.main ul li::before{
    position: absolute;
    content: "";
    left: -50px;
    top: 15px;
    background: url(../images/list1.png) no-repeat 6px 5px;
    background-size: 35px;
    width: 45px;
    height: 20px;
}
.planDtlsInner ul li i,
main ul li i{
	font-size: 20px;
	line-height: 16px;
	margin-right: 10px;
}
.planDtlsInner{ 
	position: relative;
    box-shadow: none;
    border: none;
    border-radius: 0;
    padding: 0;
    min-height: inherit;
    text-align: left;
}
.planDtlsInner .top{
	background: url(../images/frame2.png) no-repeat 0 0;
    background-size: 250px;
    padding: 60px 20px;
    position: relative;
    display: table;
    margin: 0 auto;
    width: 250px;
    height: 207px;
}
.planDtlsInner .bottom{ padding: 15px 30px; }

.planDtlsInner h4{ 
    font-size: 22px;
    line-height: 22px;
    color: #242424;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    letter-spacing: 1px;
    text-align: center;
}
.planDtlsInner h2{ 
	font-size: 22px;
    line-height: 24px;
    color: #1e9300;
    text-transform: capitalize;
    margin: 0;
    font-weight: bold;
    background: rgba(255,255,255,0.4);
    padding: 2px 15px;
    text-align: center;
    border-radius: 38px;
    display: table;
    margin: 0 auto;
}

.flatpickr-months .flatpickr-month,span.flatpickr-weekday,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays{background: #d4ffd6 !important; color: #000 !important;}
body .flatpickr-months .flatpickr-prev-month:hover svg, body .flatpickr-months .flatpickr-next-month:hover svg,
body .flatpickr-months .flatpickr-prev-month svg, body .flatpickr-months .flatpickr-next-month svg{
	fill: #000 !important;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after{
		border-bottom-color: #d4ffd6 !important;
}
.planDtlsInner h2 b{font-size: 28px;}
.membershipplans{
	padding: 50px 0 50px 0;
}
.membershipForm{
	box-shadow: 0 2px 5px 1px #ece7e4;
    padding: 20px 15px;
}
.membershipForm .tophead{
	text-align: center;
}
.membershipForm label{
	font-size: 16px;
	line-height: 22px;
	color: #000;
	font-weight: 600;
	margin: 0 0 4px 0;
}
.membershipForm label span{
	color: #f00;
	font-size: 20px;
}
.membershipForm input,
.membershipForm select,
.membershipForm textarea{
	width: 100%;
	padding: 10px;
	border: 1px solid #5aa846;
}
.recaptha{
	margin: 0 0 15px 0;
}
.submitOuter button,
.membershipForm input[type=submit]{
	background: #62a9ca;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    border: none;
    padding: 10px 15px;
}
.submitOuter button:hover,
.membershipForm input[type=submit]:hover{
	background: #5aa846;
}
.eventSection{
	padding: 59px 0 50px 0;
}
.eventSection .eventInner {
    margin: 0 0 50px 0;
    transition: 0.5s;
    border-bottom: 2px solid #fff;
    position: relative;
}
.eventSection .eventInner:hover{
	border-bottom: 2px solid #fec400;
    box-shadow: 0 5px 15px 5px #dbe4e3;
    transition: 0.5s;
}
.eventSection .eventInner a{
	display: block;
} 
.eventSection .eventInner .eventImg{
	overflow: hidden;
	border-radius: 10px 10px 0 0;
}
.iconDiv{
	position: absolute;
	top: -30px;
	right: 0;
}
.iconDiv a{
	width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 10px;
    background: #fff;
    box-shadow: 0px 2px 5px #acacac;
    margin: 0 10px 0 0;
    display: inline-block !important;
}
.iconDiv a i{
	font-size: 20px;
}
.eventSection .eventInner .eventImg img{
	transform: scale(1);
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	transition: 1s;
	-webkit-transition: 1s;
	-moz-transition: 1s;
	border-radius: 10px 10px 0 0;
	width: 100%;
}
.eventSection .eventInner:hover .eventImg img{
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	transition: 1s;
	-webkit-transition: 1s;
	-moz-transition: 1s;
}
.eventSection .eventInner a img{
	width: 100%;
	height: 200px;
	object-fit: cover;
}
.eventSection .eventInner .event-list-details {
	padding: 20px 10px;
	text-align: left;
	position: relative;
}
.eventSection .eventInner .event-list-details h4 a,
.eventSection .eventInner .event-list-details h4{
	font-size: 18px;
    line-height: 24px; 
    color: #5fa7c9;
    font-weight: 400;
    text-transform: uppercase;
}
.eventSection .eventInner .event-list-details h4 a:hover{
	color: #000;
}
.eventSection .eventInner .event-list-details h2{
	font-size: 21px;
    line-height: 26px; 
    font-weight: 600;
    color: #002d43;
    height: 50px;
    overflow: hidden;
}
.eventSection .eventInner .event-list-details  .event-list-when span{
	display: block;
    font-weight: 600;
    margin: 0 0 10px 0;
    color: #61a9ca;
}
.event-list-description p{
	font-size: 16px;
    line-height: 22px;
    color: #333;
    margin: 0 0 0px 0;
}
.eventSection .eventInnerSec{
	display: block;
	margin: 0 0 60px 0;
}
.eventSection .eventInnerSec .eventImg{
	width: 30%;
	display: inline-block;
	border-radius: 10px;
	vertical-align: top;
}
.eventSection .eventInnerSec .eventImg img{
	border-radius: 10px;
}
.eventSection .eventInnerSec .event-list-details{
	width: 68%;
	display: inline-block;
	vertical-align: top;
}
.eventSection .eventInnerSec .event-list-details h2{
	height: auto;
}
.eventSection .eventInnerSec .event-list-details .iconDiv{
	position: absolute;
	right: 0;
	bottom: -15px;
	top: auto;
	text-align: right;
}
.eventSection .eventInnerSec .event-list-details {
	padding: 20px 10px 40px 10px;
	text-align: left;
}
/*START TRAVEL SECTION*/
	.travelSection{
		padding: 30px 0 30px 0;
	}
	.travelOuter{
		display: flex;
		width: 100%;
		margin: 0 0 50px 0;
	}
	.travelOuterSec{
		margin: 0 0 50px 0;
		background: #f8f8f8;
	}
	.travelImg {
		vertical-align: top;
		width: 50%;
		padding: 0  0 0;
		position: relative;
		margin-right: 30px;
		line-height: 0;
	} 
	.travelImg .note{
		display: block;
	    background: #5ba5c8;
	    color: #fff;
	    padding: 5px 10px;
	    border-radius: 10px;
	    font-size: 13px;
	    line-height: 16px;
	    position: absolute;
	    right: 0;
	    bottom: 0; width: 100%;
	    opacity: 0;
	    transition: 0.5s;
	    z-index: 1;
	}
	.travelImg:hover .note{ opacity: 1;}

	.travelOuterblck {
		display: block;
	}
	.travelOuterblck .travelImg,
	.travelOuterblck .travelContent {
		width: 100%;
	}
	.travelOuterblck .travelImg img{
		height: auto;
		object-fit: cover;
	}
	.travelOuterblck .travelImg img.nt{
		    max-width: 100px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
	}
	.travelOuterblck .travelImg{
		margin: 0 0 20px 0;
	}
	.travelImg .note strong{color: #fff;}
	.travelImg .credit{
		position: absolute;
	    background: rgba(90,168,70,.9);
	    color: #fff;
	    left: 5px;
	    top: 5px;
	    font-size: 14px;
	    line-height: 16px;
	    padding: 5px 10px;
	    border-radius: 5px;
	    transform: rotateY(90deg);
	    transform-origin: 0 0;
	    transition: 0.5s;
	}
	.travelImg:hover .credit{
		transform: rotateY(0deg);
	}
	.travelImg img,.travelImg video{
		border-radius: 10px;
		width: 100%;

	}
	.travelContent{
		vertical-align: top;
		padding: 0 0 0;
		width: 50%;
	}
	.travelContent .iconlist{ list-style: none; margin: 0 0 15px; padding: 0; }
	.travelOuterSec  .travelContent .iconlist{display: none;}
	.travelContent .iconlist li{display: inline-block; vertical-align: top;
		margin-right: 6px; text-align: center; max-width: 24.5%;margin-bottom: 8px;
	}
	.travelContent .iconlist li a{display: block;
	    padding: 3px;
	    font-weight: 600;
	    color: #5aa846;
	    font-size: 14px; line-height: 18px;
	    position: relative;
	}
	.travelContent .iconlist li a:hover{color: #5ba5c8;}
	.travelContent .iconlist li span{display: block;}
	.travelContent .iconlist li span.ico{margin: 0 0 12px;}
	.travelContent .iconlist span img{display: inline-block;
		height: 50px;
	}
	.travelContent .iconlist li span.text{
		background: #5aa846;
	    color: #fff;
	    padding: 3px 10px;
	    border-radius: 5px;
	    position: absolute;
	    right: -30px;
	    top: -25px;
	    opacity: 0;
	    transition: .5s;
	}
	.travelContent .iconlist li a:hover span.text{
		opacity: 1;
	}
	.travelContent h4{
		font-size: 21px;
	    line-height: 26px; 
	    color: #002d43;
		margin: 0 0 20px;
		font-weight: 600;
	} 
	.travelContent p{
		font-size: 16px;
	    line-height: 24px;
	    color: #242424;
	    margin: 0 0 10px 0;
	}
	.destholder{
		position: relative;
	}
	.destholder .inswidget{
		position: relative;
	    right: auto;
	    bottom: auto;
	    width: 30%;
	    margin: 0 auto;
	}
	.destholder .inswidget iframe
	{
		width: 100%;
		height: 530px;
		display: none;
	}
	.destholder .inswidget img{
		width: 100%;
		height: 530px;
	}
	.postedby{
		font-size: 16px;
	    line-height: 22px;
	    color: #5ba5c8;
	    font-weight: 600;
	}
	.travelImgSmall{
		position: relative;
	}
	.travelImgSmall{
		width: 50%;
		height: 100%;
	}
	.travelImgSmall img{
		height: 170px;
		object-fit: cover;
	}
	.travelImg{
		position: relative;
	}
	/*.date{
		position: absolute;
		bottom: 10px;
		left: 10px;
		font-size: 18px;
		line-height: 24px;
		color: #fff;
		background: #5aa846;
		padding: 2px 10px 5px 10px;
		border-radius: 4px;
		font-style: italic;
		display: inline-block;
		margin: 0 0 5px 0;
	}*/
	.travelOuterSec .travelImg{
		width: 100%;
	}
	.travelOuterSec .travelImg img,.travelOuterSec .travelImg video{
		height: 230px;
		width: 100%;
		object-fit: cover;
	}
	.travelOuterSec .travelContent{
		width: 100%;
		padding: 10px;
	}
	.travelOuterSec .travelContent 
	.travelOuterSecbigImg .travelImg img{
		height: 230px;
		width: 100%;
		object-fit: cover;
	}
	.travelOuterSec  h5,
	.travelOuter h5{
		color: #242424;
	    font-size: 20px;
	    line-height: 22px;
	    padding: 6px 0;
	}
	.travelOuterblck .travelImg {
		position: relative;
	}
	.travelOuterblck .travelImg .destination-logo{
		display: block;
		position: absolute;
		top: 4px;
		right: 4px;
		max-width: 150px;
	}
	.travelOuterblck .travelImg .destination-logo img{
		height: auto;
		max-width: 100%;
	}
/*END TRAVEL SECTION*/
/*START CONTACT US SECTION*/
	.touchForm{/*
		max-width: 80%;
	    margin: 0 auto;
	    background: #f3f3f3;*/
	    /*padding: 20px 15px;
	    box-shadow: 0px 4px 7px #acacac;*/
	}
	.touchForm h2{
		font-size: 30px;
	    line-height: 40px;
	    font-weight: 600;
	    text-transform: capitalize;
	    margin: 0 0 25px 0;
	}
	.touchFormInner p{
		font-size: 18px;
		line-height: 24px;
		color: #4c4b4a;
		margin: 0 0 20px 0;
	}
	.touchImg{
		position: relative;
	}
	.womenImg{
		position: absolute;
	    top: -50px;
	    right: -50px;
	    height: 520px;
	}
	.touchForm .formdiv input,
	.touchForm .formdiv textarea{
		width: 100%;
		border: none;
		background: #fff;
		font-size: 15px;
		line-height: 22px;
		color: #4c4b4a;
		padding: 10px 15px;
		border: 1px solid #ccc;
	}
	.touchForm .formdiv input:focus,
	.touchForm .formdiv textarea:focus{
		outline: none;
	}
	.touchForm .formdiv .form-group{
		margin: 0 0 20px 0;
	} 
	.comingSoon .btnholder{ padding: 10px 0 0 0; }
	.buttonOuter input[type=submit],
	.buttonOuter button,.comingSoon .btnholder .btn,
	.buttonOuter button[type=submit] { 
		display: inline-block;
	    color: #fff;
	    text-transform: capitalize;
	    font-size: 18px;
	    line-height: 20px;
	    background-size: cover;
	    transition: .5s;
	    font-weight: 600;
	    padding: 50px 30px;
	    position: relative;
	    transition: .5s;
	    border-radius: 5px;
	    background: url(../images/btn1.png) no-repeat 0 0;
		border: none;
		min-width: 170px;
		width: auto;
	} 
	.buttonOuter input[type=submit]:hover,
	.buttonOuter button:hover ,.buttonOuter button[type=submit].btn-primary:hover ,
	.buttonOuter button[type=submit]:hover {
		background: url(../images/btn2.png) no-repeat 0 0;
	}
	.touchForm .formdiv input[type=submit]:focus,
	.touchForm .formdiv button:focus,
	.logholder input[type="submit"]:focus,
	.logholder button[type="submit"]:focus{
		outline: none;
		box-shadow: none;
	}
	.contactinfoInner{
		margin: 0 0 30px 0;
	}
	.contactinfoInner span{
		width: 55px;
		height: 55px;
		display: inline-block;
		vertical-align: top;
		margin: 0 8px 8px 0;
		transition: 1s;
		-webkit-transition: 1s;
		-moz-transition: 1s;
	}
	.contRight{
		display: inline-block;
		vertical-align: top;
		width: 80%;
	}
	.contactinfoInner span.contactLoaction{
		background: url(../images/loactionIcon1.png) no-repeat 3px 4px;
		background-size: 50px;
	}
	.contactinfoInner:hover span.contactLoaction{
		background: url(../images/loactionIcon1.png) no-repeat 3px -60px;
		background-size: 50px;
		transition: 1s;
		-webkit-transition: 1s;
		-moz-transition: 1s;
	}
	.contactinfoInner span.contactPhone{
		background: url(../images/phone2.png) no-repeat 0px 0px;
		background-size: 50px;
	}
	.contactinfoInner:hover span.contactPhone{
		background: url(../images/phone2.png) no-repeat 0px -60px;
		background-size: 50px;
		transition: 1s;
		-webkit-transition: 1s;
		-moz-transition: 1s;
	}
	.contactinfoInner span.contactMail{
		background: url(../images/mail2.png) no-repeat 0px 0px;
		background-size: 50px;
	}
	.contactinfoInner:hover span.contactMail{
		background: url(../images/mail2.png) no-repeat 0px -60px;
		background-size: 50px;
		transition: 1s;
		-webkit-transition: 1s;
		-moz-transition: 1s;
	}
	.contactinfoInner h5{
		font-family: 'Raleway', sans-serif;
	    font-size: 20px;
	    line-height: 22px;
	    color: #5aa846;
	    font-weight: 600;
	    margin: 0 0 6px 0;
	    /*text-align: center;*/
	}
	.contactinfoInner p{
		font-family: 'Raleway', sans-serif;
		font-size: 18px;
		line-height: 24px;
		color: #4a4a4a;
		margin: 0 0 0px 0;
		vertical-align: bottom;
		/*text-align: center;*/
	}
	.contactinfoInner p span{
		display: block;
		width: auto;
		height: auto;
	}
	.contactinfoInner p a{
		font-size: 20px;
		line-height: 26px;
		color: #4a4a4a;
	}
	.contactinfoInner p a:hover{
		color: #5ba5c8;
	}
	.contactinfoSection{
		padding: 50px 0 20px 0;
	}
	.contactusSection{
		padding: 10px 0 100px 0;
	}
	.availDtls h2{
		font-size: 30px;
	    line-height: 40px;
	    font-weight: 600;
	    text-transform: capitalize;
	    margin: 0 0 25px 0;
	}
	.mapOuter{
		height: 300px;
	    margin-top: -150px;
	    z-index: 10;
	    border: 5px solid #5eaa4a;
	    margin-bottom: 20px;
	}

	.vldtn-error{border: 1px solid #f00 !important;}
	.vldtn-error::-webkit-input-placeholder {
	  color: red;
	} 
	.vldtn-error:-ms-input-placeholder {
	  color: red;
	} 
	.vldtn-error::placeholder {
	  color: red;
	}
/*END CONTACT US SECTION*/
/*START ROUTE DETAILS*/
	.routeDetails{
		/*position: relative;*/
	}
	.routeDetails.routeplanner .routeplanleft{
		width: 50%;
	    overflow: auto;
	    height: 90%;
	    padding: 0 0 50px 0;
	}
	.routeDetails.routeplanner .routeplanleft::-webkit-scrollbar-track{
    	border-radius: 10px;
	}
	.routeDetails.routeplanner .routeplanleft::-webkit-scrollbar{
	    width: 6px;
	}
	.routeDetails.routeplanner .routeplanleft::-webkit-scrollbar-thumb{
	    border-radius: 10px;
	    background-color: #34af3a;
	}
	.routeDetails.routeplanner .routeplanright{
		width: 50%;
	}
	.routeDetailsInner .routeDtlsContent{
		position: relative;
	    max-width: 90%;
	    background: #fff;
	    margin: 0 auto;
	    margin-top: -40px;
	    padding: 20px;
	}
	.item-wrapper{
		margin: 0 0 30px 0;
	}
	.title-author{
		text-align: center;
	}
	.title-author img{
		width: 80px;
		height: 80px;
		margin: 0 auto 10px;
		border-radius: 50%;
	}
	.title-author h5{
		font-size: 16px;
		line-height: 22px;
	}
	.title-author h5 a{
		color: #34af3a;
	}
	.stats {
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: flex-start;
	}
	.routeDtlsContent h2{
		color: #45a5cd;
	    display: inline-block;
	    font-size: 26px;
	    margin: 0 0 10px 0;
	    max-width: 100%;
	    overflow: hidden;
	    padding-top: 1rem;
	    text-overflow: ellipsis;
	}
	.flag-img,
	.flag-body{
		display: inline-block;
		vertical-align: top;
	}
	.flag-img i{
		font-size: 25px;
		margin: 0 10px 0 0;
		color: #34af3a;	
	}
	.item-label{
		display: block;
	}
	.flag-body{
		color: #34af3a;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
	}
	.action_button{
		display: flex;
		justify-content: space-between;
		padding: 20px;
	}
	.action_button button{
		background: #45a5cd;
	    font-size: 18px;
	    line-height: 24px;
	    border: none;
	    color: #fff;
	    padding: 8px 36px;
	}
	.action_button button:hover{
		background: #34af3a;	
	}
	.routeGraph h4{
		font-size: 18px;
		line-height: 24px;
		font-weight: 600;
		color: #333;
	}
	.routeGraph img{
		width: 100%;
		max-width: 100%;
	}
	.aboutContent {
		margin: 0 0 15px 0;
	}
	.aboutContent h4{
		font-size: 18px;
		line-height: 24px;
		font-weight: 600;
		color: #333;
	}
	.aboutContent p{
		font-size: 16px;
		line-height: 22px;
		color: #333;
		margin: 0 0 10px 0;
	}
	.aboutContent textarea{
		width: 100%;
		box-shadow: 5px;
		border: 1px solid #ccc;
		margin: 0 0 15px 0;
		padding: 10px;
	}
	.commentBtnOuter {
		text-align: right;
	}
	.commentBtnOuter button{
		background: #45a5cd;
	    font-size: 18px;
	    line-height: 24px;
	    border: none;
	    color: #fff;
	    padding: 8px 36px;
	}
	.commentBtnOuter button:hover{
		background: #34af3a;	
	}
/*END ROUTE DETAILS*/
/*START DETAIL SCETION*/
	.detailSection{
		padding: 60px 0 40px 0;
		border-bottom: 1px solid #f3f3f3;
	}
	.viewport{
		border: 1px solid #f3f3f3;
		padding: 30px 20px;
		text-align: center;
		margin: 0 0 20px 0;
		height: 400px;
	}
	.description{
		padding-left: 40px;
	}
	.description h4{
		font-size: 28px;
	    line-height: 35px;
	    /* font-family: 'Raleway', sans-serif; */
	    color: #002d43;
	    margin: 0 0 10px 0;
	    font-weight: 700;
	}
	.description p{
		font-size: 17px;
		line-height: 26px;
		color: #585858;
		margin: 0 0 10px 0;
		text-align: justify;
	}
	.descriptionBottom p{
		margin: 0 0 30px 0;
	}
	.description a.enquireBtn{
		display: inline-block;
		margin-top: 20px;
		background: #62a9ca;
	    color: #fff;
	    font-size: 18px;
	    line-height: 24px;
	    padding: 10px 30px;
	    border-radius: 50px;
	    font-weight: 600;
	    border: none;
	}
	.description a.enquireBtn:hover{
		background: #5aa846;
	}
	.inquireImg img{
		max-width: 100%;
		width: 100%;
		margin: 0 0 20px 0;
	}
	.inquireImg {
	    overflow: hidden;
	    line-height: 0;
	    position: relative;
	    height: 380px;
	    margin: 0 0 25px 0;
	}
	.inquireImg img{
		height: 379px;
	}
	.inquireImg:after{
		position: absolute;
	    content: "";
	    left: 0;
	    top: 0;
	    background: url(../images/frame1.png) no-repeat 0 0;
	    background-size: 100%;
	    width: 100%;
	    height: 100%;
	}
	.inquireImg h6{
		font-size: 18px;
	    line-height: 24px;
	    font-family: 'Raleway', sans-serif;
	    color: #5aa846;
	    font-weight: 600;
	    margin: 0 0 10px 0;
	}
	ul.shareIcon{
		list-style: none;
		padding: 0;
		margin: 0;
	}
	ul.shareIcon li{
		display: inline-block;
		padding: 0 2px;
	}
	ul.shareIcon li:first-child{
		padding-left: 0;
	}
	ul.shareIcon li a{
		display: inline-block;
	}

/*END DETAIL SCETION*/
/*START PROFILE SECTION*/
	.profileSection{
		padding: 50px 0 50px 0;
	}
	.profileList {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	.profileList li{
		display: block;
		padding: 5px 0;
	}
	.profileList li a{
		display: block;
		color: #61a9ca;
    	font-family: 'Raleway', sans-serif;
    	font-size: 15px;
    	line-height: 22px;
    	font-weight: 600;
	}
	.profileList li a i{
		font-weight: 400;
		margin: 0 5px 0 0;
	}
	.profileList li a:hover{
		color: #000;
	}
	.strengthList {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	.strengthList li{
		display: block;
		padding: 8px 0;
		position: relative;
	}
	/*.strengthList li:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		height: 100px;
		background: #333;
	}*/
	.strengthList li a{
		display: block;
		color: #333;
    	font-family: 'Raleway', sans-serif;
    	font-size: 18px;
    	line-height: 24px;
    	font-weight: 600;
	}
	.strengthList li a img{
		font-weight: 400;
		margin: 0 5px 0 0;
		width: 20px;
	}
	.strengthList li a.completed{
		color: #29ae07;
	}
	.strengthList li a.notcompleted{
		color: #f00;
	}
	.progessOuter{
		background: #f0f0f0;
		width: 100%;
		height: 5px;
		border-radius: 5px;
		position: relative;
		margin: 0 0 40px 0;
	}
	.progessOuter span{
		font-family: 'Roboto', sans-serif;
		position: absolute;
		bottom: -24px;
		right: 0;
		color: #29ae07;
		font-size: 15px;
		line-height: 22px;
		font-weight: 600;
	}
	.progressInner{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background: #29ae07;
	}
	.strengthList li a.blank{
		color: #ddd;
	}
	.strengthList li a.blank span{
		width: 20px;
		height: 20px;
		border: 2px solid #ccc;
		display: inline-block;
		margin: 0 5px 0 0;
		border-radius: 50%;
		vertical-align: middle;
	}
	.updateBtnOuter{
		margin: 5px 0 0 0;
	}
	.updateBtnOuter button{
		background: #61a9ca;
		font-size: 15px;
		line-height: 22px;
		color: #fff;
		padding: 10px 20px;
		border: none;
	}
	.updateBtnOuter button:hover{
		background: #34af3b;
	}
	.profileSettings h2{
		font-size: 25px;
	    line-height: 34px;
	    text-transform: capitalize;
	    margin: 0 0 4px;
	    font-weight: 600;
	}
	.profilefrm{
		padding: 0px 0px;
	    margin: 30px 0 0 0;
	}
	.profilefrm label,
	.radioOuter h6{
		font-size: 14px;
		line-height: 22px;
		text-transform: uppercase;
		margin: 0 0 4px 0;
		color: #000;
		font-weight: 600;
	}
	.profilefrm input,
	.profilefrm select,
	.profilefrm textarea{
		width: 100%;
		padding: 10px;
		border: 1px solid #ccc;
		font-size: 15px;
		line-height: 22px;
		color: #333;
	}
	.radioOuter label{
		display: inline-block;
		margin: 0 15px 0 0;
	}
	.radioOuter label span{
		font-size: 15px;
		line-height: 22px;
		text-transform: capitalize;
		color: #5aa846;
	}
	.radioOuter label input[type=radio]{
		width: 15px;
		height: 15px;
		vertical-align: middle;
		display: inline-block;
		margin: 0 5px 0 0;
	}
	.radioOuter {
		margin: 0 0 25px 0;
	}
	.radioOuter h6{
		margin: 0 0 8px 0
	}
	.profilefrm .note{
		font-size: 13px;
	    line-height: 11px;
	    text-align: right;
	    text-transform: capitalize;
	    display: block;
	    font-family: 'Roboto', sans-serif;
	    font-style: italic;
	}
	.photoUpload{
		margin: 0 0 25px 0;
	}
	.photoUploadInner{
		height: 200px;
		border: 1px solid #ccc;
	}
	.photoUploadInner button{
		background: #61a9ca;
	    font-size: 15px;
	    line-height: 22px;
	    color: #fff;
	    padding: 10px 20px;
	    border: none;
	    position: relative;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    -webkit-transform: translate(-50%, -50%);
	    -moz-transform: translate(-50%, -50%);
	}
	.photoUploadInner button:hover{
		background: #34af3b;
	}
	.photoName{
		background: #d3f6ca;
	    font-family: 'Roboto', sans-serif;
	    font-size: 15px;
	    line-height: 22px;
	    padding: 10px;
	    color: #000;
	    position: relative;
	    margin: 5px 0 0 0;
	}
	.photoName p{
		margin: 0;
	}
	.photoName a.remove{
		position: absolute;
		top: 10px;
		right: 10px;
		width: 20px;
	}
	.availableTym {
		margin: 0 0 20px 0;
	}
	.availableTym input[type=checkbox]{
		width: 15px;
		height: 18px;
		display: inline-block;
		vertical-align: text-top;
	}
	.field-inline{
		margin: 0 0 20px 0;
	    display: flex;
	    align-items: center;
	    background: #c7f2bd;
	    padding: 10px;
	}
	.field-group{
		width: 33%;
		margin: 0 10px 0 0;
	}
	.field-group:last-child{
		margin: 0;
	}
	.field-group span{
		font-size: 18px;
	    line-height: 24px;
	    color: #000;
	    font-weight: 600;
	    margin: 0 0px 0 5px;
	}
/*END PROFILE SECTION*/


/*STRT COUPON PAGE*/


.cpupon-section{
	padding:50px 0;
}

.coupon-holder{
	display: flex;
	flex-direction: row;
	align-items: center;
	box-shadow:0 0 25px -7px rgb(173, 211, 162);
	transition: 0.3s;
	margin-bottom: 40px;
	 
}

.coupon-holder:hover{
	box-shadow:0 0 25px -7px rgb(96, 168, 202);
}

.cupn-brnd-hldr{
	width: 45%;
	text-align: center;
	border-right: 1px solid #ddd;

}

.cpn-brnd-img-hldr{
	overflow: hidden;
}

.cupn-brnd-hldr img{
	transform: scale(1);
	transition: 0.5s;
}

.coupon-holder:hover .cupn-brnd-hldr img{
	transform: scale(1.1);
}

.cupn-detls-hlder h4{
	font-size: 22px;
    margin: 0;
    line-height: 35px;
}

.cupn-detls-hlder h4 span{
	color: #5aa846;
	transition: ease-in-out 0.5s;
}

.coupon-holder:hover .cupn-detls-hlder h4 span{
	font-weight: bold;
}

.cupn-detls-hlder h3{
  font-size: 23px;
  margin: 0;

}


span.cpnsving-txt{
	width: 100%;
    background: #e7e7e7;
    display: block;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    border-top:1px solid #eeeeee;
    color: #000;
}


.cupn-detls-hlder{
	padding:20px;
	align-items: center;
}


.cuppon-code-btn-hlder{
	padding-top: 15px;
}


a.cupncd-anchr{
	width: 116px;
    height: 35px;
    background: #fff;
    display: inline-block;
    overflow: hidden;
    padding: 6px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 16px;
    border: 2px solid #5aa846;
    vertical-align: middle;
    text-align: center;
    position: relative;
}

.cupon-overlay{
	position: absolute;
    top: 0;
    left: 0;
    width: 81%;
    height: 100%;
    background: #5aa846;
    z-index: 12;
    line-height: 20px;
    padding: 6px;
    color: #fff;
    font-size: 15px;
    transition: 0.8s;
}

.cupon-overlay:after{
	content: "";
    position: absolute;
    top: 0;
    right: -10px;
    width: 26px;
    height: 135%;
    background: #5aa846;
    z-index: -1;
    transform: rotate(-27deg);
    transition: 0.3s;
}

a.cupncd-anchr:hover .cupon-overlay{
	left: -110px;
}

a.cupncd-anchr:hover .cupon-overlay:after{
	transform: rotate(-48deg);
	top: 0;
	right: -5px;
	height: 97%;
	width: 26px;
}

span.expiretxt{
	font-size: 15px;
    padding: 0 7px;
    font-weight: 500;
    color: #767676;
}

.cuppon-code-btn-hlder a.fevorite-listicon{
	color: #6eb001;
	cursor: pointer;
	transition: 0.3s;
}

.cuppon-code-btn-hlder a.fevorite-listicon:hover{
	color:#27970a;
}


/*END COUPON PAGE*/


/*START PERTNER PAGE*/

.partner-section{
	padding: 40px 0;
}

.patrner-img-hldr{
	text-align: center;
    padding: 10px;
    background: #fff;
    box-shadow: 2px 2px 5px 1px #ddd;
    overflow: hidden;
    margin: 15px 0;
    border-radius: 5px;
}

.patrner-img-hldr img{
	transform: scale(1);
    transition: 0.5s;
}

.patrner-img-hldr:hover img{
	transform: scale(1.1);
	/*filter:drop-shadow(-10px 0px 6px #a5a6a5);*/
}
.malink{display: inline-block; margin: 0 0 5px;}
/*END PARTNER PAGE*/

.comingSoon{
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	background: #fff;
	padding: 20px 20px;
	border-radius: 10px;
	text-align: center;
}
.comingSoon.comingSoon1{
	position: relative; display: table; margin: 0 auto;
	top: auto;
	left: auto;
	transform: translate(0, 0);
	box-shadow: 1px 1px 7px 0px #ddd;
	-webkit-transform: translate(0, 0);
}


.comingSoon img{
	width: 200px;
	height: auto;
	margin: 0 0 20px 0;
}
.comingSoon p.green{
    color: #5aa846;
    font-weight: bold;
    font-size: 30px;
    line-height: 34px;
}
.comingSoon p{
	margin: 0 0 10px 0;
}
.expertBtn{
	position: fixed;
    top: 40%;
    right: -85px;
    padding: 8px 15px;
    border-radius: 5px;
    background: #5aa846;
    border: 2px solid #fff;
    color: #fff;
    /* margin: 0 8px; */
    text-transform: capitalize;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    z-index: 120; 
}
.expertBtn img{
	width: 50px;
}
.expertBtn:hover{
	background: #fff;
    border: 2px solid #5aa846;
    color: #000;
}
.expertBtn:hover img{
	filter: invert(1);
}
.gensmallmodal .modal-dialog{
	max-width: 600px;
}
.gensmallmodal .modal-body .logholder{
	width: 100%;
	padding-bottom: 100px;
}
.gensmallmodal .logholder >div{
	position: relative;
	top: auto;
	left: auto;
	width: 100%;
	transform: translate(0%,0%); 
     -webkit-transform: translate(0%,0%); 
}
.gensmallmodal .logholder .form-group iframe,
.touchForm .form-group iframe{
	transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
	transform: scale(0.9);
	-webkit-transform: scale(0.9);
}
.gensmallmodal .logholder h3{
	margin: 20px 0 15px 0;
}
.btnholder a.btn.contctBtn{
	padding: 50px 37px;
}
.btnholder a.btn.getintouchBtn{
	padding: 50px 35px;
}
.btnholder a.btn.contctBtn:focus{
	outline: none;
	box-shadow: none;
}
.btnholder a.btn.contctBtn:focus{
	outline: none;
}
.contctfrDtls a{
	background: #5aa846;
	display: block;
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	padding: 15px;
	border-radius: 5px;
}
.contctfrDtls a:hover{
	background: #5ba5c8;
}
.contctfrDtls{
	margin: 0 0 50px 0;
}
.destinationFilter{
	margin: 0 0 40px 0;
}
.destinationFilter ul{
	list-style: none;
	padding: 0;
	margin: 0 0 20px 0;
	text-align: center;
}
.destinationFilter li{
	display: inline-block;
	padding: 0 10px;
	position: relative;
}
.destinationFilter li:after{
	content: '';
    position: absolute;
    top: 8px;
    right: -3px;
    height: 10px;
    width: 2px;
    background: #ccc;
}
.destinationFilter li:last-child:after{
	display: none;
}
.destinationFilter li a{
	display: inline-block;
	font-weight: 600;
    color: #5aa846;
    font-size: 18px;
    line-height: 24px;
}
.destinationFilter li a:hover{
	color: #5ba5c8;
}
.destinationFilter li a.active{
	text-decoration: underline;
}
/*.travelContent .iconlist li a .ico {
    margin-right: 15px;
    width: 50px;
    position: relative;
    display: inline-block;
    vertical-align: top;
}
.travelContent .iconlist li a .ico:after {
    position: absolute;
    content: "";
    left: -70px;
    top: -22px;
    background: url(../images/icon-c.png) no-repeat 0 0;
    background-size: 132px;
    width: 132px;
    height: 100px;
}*/
/*START SMART SOLUTION*/
	.eventSection .smartDtls {
		margin: 0;
	}
	.eventSection .smartDtls .smartTop{
		display: flex;
		align-items: center;
		padding: 10px;
		border-bottom: 1px solid #d5d5d5;
	}
	.eventSection .smartDtls .companyLogo{
		display: inline-block;
		background: #e7e7e7;
    	padding: 10px;
	}
	.eventSection .smartDtls .companyLogo img{
		display: inline-block;
	    max-width: 100%;
	    height: 35px;
	    width: auto;
	}
	.eventSection .smartDtls .smartTop h4{
		font-size: 20px;
	    line-height: 26px;
	    font-weight: 600;
	    color: #002d43;
	    margin: 0;
	}
	.eventSection .smartDtls .smartTop .smartLeft{
		width: 50%;
		margin-right: 5px;
	}
	.eventSection .smartDtls .smartTop .smartright{
		text-align: right;
		width: 50%;
	}
	.eventSection .smartDtls a.link{
		font-weight: 600;
		color: #5aa846;
		margin: 0 0 15px 0;
		font-size: 13px;
	    font-weight: 600;
	    color: #5aa846;
	}
	.eventSection .smartDtls h5{
		font-size: 18px;
	    line-height: 24px;
	    font-weight: 600;
	    color: #002d43;
	}
	.eventSection .smartDtls .smrtenq {
		margin: 10px 0 0 0;
		text-align: left;
	}
	.eventSection .smartDtls .smrtenq a{
		padding: 50px 29px;
	    display: inline-block;
	    min-width: 170px;
	    color: #fff;
	}
	.eventSection .smartDtls .smrtenq a:hover{
		color: #fff;
	}
	.smart-destination{
		padding-top: 20px;
	}
	.keywordSearch{
		position: relative;
	}
	.keywordSearch input{
		width: 100%;
	    padding: 6px 8px;
	    border-radius: 5px;
	    border: 1px solid #ddd;
	}
	.keywordSearch input[type=submit]{
		text-transform: uppercase;
	    background: #5aa846;
	    color: #fff;
	    text-transform: uppercase;
	    border: 1px solid #5aa846;
	    transition: 0.5s;
	    -webkit-transition: 0.5s;
	}
	.keywordSearch input[type="submit"]:hover {
	    background: #5ba5c8;
	    border: 1px solid #5ba5c8;
	}
	/*.eventSection .smartDtls.smartDtlsSec .smartTop{
		width: 30%;
	    display: inline-block;
	    border-bottom: none;
	    vertical-align: top;
	}
	.eventSection .smartDtls.smartDtlsSec  .smartTop .smartLeft{
		width: 100%;
		margin-bottom: 20px;
	}
	.eventSection .smartDtls.smartDtlsSec  .smartTop .smartright{
		width: 100%;
		text-align: left;
	}
	.smartDtlsSec .event-list-details{
		width: 68%;
		display: inline-block;
	}*/
/*END SMART SOLUTION*/
.event-list-details .vdo{margin: 0 0 15px;}
.event-list-details .vdo iframe{
	width: 100%; height: 180px;
}
.event-list-details .vdo img{
	width: 100%; height: 180px;
	object-fit: cover; object-position: center;
}
.vdo img{
	width: 100%; height: 180px;
	object-fit: cover; object-position: center;
}
.event-list-details .author{padding: 10px  0 0 0; display: block;}
 
.sucess_alert .logholder:after{
	display: none;
}
.sucess_alert.gensmallmodal .modal-body .logholder{padding-bottom: 30px;}
.sucess_alert input[type="submit"]{width: auto; padding: 10px 25px;}
.logholder input[type="submit"]:hover,
.logholder button[type="submit"]:hover{
	background: #3598c1;
}

.sucess_alert .logholder{padding-bottom: 30px;}
.sucess_alert .logholder:after{
	display: none;
}
.sucess_alert.gensmallmodal .modal-body .logholder{padding-bottom: 30px;}
.sucess_alert input[type="submit"]{width: auto; padding: 10px 25px;}
.logholder input[type="submit"]:hover{
	background: #3598c1;
}

.getexpert.mb-register{
	display: none;
}



/* START FIXING 18.12.20 */




/* END FIXING 18.12.20 */



.planDtlsdigital-table{margin: 0 0 50px;}
.planDtlsdigital-table table tr th{
	background: none; border: none;
}
.planDtlsdigital-table table{
	max-width: 98%; margin: 0 auto;
}
.planDtlsdigital-table table tfoot tr:last-child td{text-align: center;}
.planDtlsdigital-table .subscribe{display: inline-block;}
.planDtlsdigital-table .top{
	background: url(../images/frame2.png) no-repeat 0 0;
    background-size: 250px;
    padding: 60px 20px;
    position: relative;
    display: inline-block;
    margin: 0 auto;
    width: 250px;
    height: 207px;
}
.planDtlsdigital-table .top h2{
	font-size: 22px;
    line-height: 24px;
    color: #1e9300;
    text-transform: capitalize;
    margin: 0;
    font-weight: bold;
    background: rgba(255,255,255,0.4);
    padding: 2px 15px;
    text-align: center;
    border-radius: 38px;
    display: table;
    margin: 0 auto;
}
.planDtlsdigital-table .top h4{
	    font-size: 22px;
    line-height: 22px;
    color: #242424;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    letter-spacing: 1px;
    text-align: center;
    font-weight: bold;
}
.planDtlsdigital-table th,.planDtlsdigital-table td{
	    background: none;
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 10px 15px;
    width: 25%;
}
.planDtlsdigital-table th{}

.planDtlsdigital-table td:nth-child(n){
	background: #fff ; 
}
.planDtlsdigital-table td:first-child{
	background: #d4ffd6 ;font-weight: bold;
}
.planDtlsdigital-table td:nth-child(2n){
	background: #f7f7f7; 
}

.planDtlsdigital-table td{}



@media (max-width: 1199px){
	.womenImg{
		height: auto;
	    position: relative;
	    top: -80px;
	}

	/*START BIKE DESTINATION PAGE*/

	.travelImg .note{
		margin-top:4px;
	}

	.destholder .inswidget img{
		height: 660px;
	}

	.travelContent .iconlist li{
		max-width: 21.5%;
	}

	/*END BIKE DESTINATION PAGE*/

}
@media (max-width: 991px){
	.travelContent h4{
		font-size: 18px;
		line-height: 24px;
	}
	.touchForm{
		max-width: 100%;
	}
	.womenImg {
	    height: auto;
	    position: relative;
	    top: -70px;
	    right: -30px;
	    max-width: 100%;
	}
	.mapOuter{
		position: relative;
		margin-top: 0;
	}
	.contactinfoSection {
	    padding: 10px 0 30px 0;
	}
	.contactinfoInner{
		margin: 0 0 20px 0;
	}
	.touchForm h2,
	.availDtls h2{
		font-size: 22px;
		line-height: 28px;
	}
	.contactinfoInner p,
	.contactinfoInner h6{
		font-size: 16px;
		line-height: 22px;
	}
	.contactinfoInner span{
		width: 35px;
		height: 35px;
	}
	.contactinfoInner span.contactPhone,
	.contactinfoInner span.contactMail,
	.contactinfoInner span.contactLoaction{
		background-size: 30px;
	}


	/*START MEMBERSHIP RESPONNSIVE*/

	.planDtlsInner .top{
		width:228px;
		height: 180px;
		background-size: 218px;
	}

	.planDtlsInner h2{
		font-size: 17px;
	}

	.planDtlsInner .bottom{
		padding: 15px;
	}

	/*END MEMBERSHIP RESPONSIVE*/


	/*STATRT DESTINATION PAGE*/

	.travelOuterSec{
		margin-bottom: 15px;
	}

	.destholder .inswidget{
		position: relative;
		width: 100%;
		height: auto;
		margin-bottom: 25px;
	}


	/*END DESTINATION PAGE*/

	/*START ABOUT PAGE*/

	.para p{
		text-align: justify;

	}

	.videodiv{
		width: 100%;
		height: 240px;
	}

	.platform_sec{
		padding-top: 50px;
	}

	/*END ABOUT PAGE*/
	.expertBtn{
 			top:350px; right: -84px; padding: 8px 15px;
 			font-size: 14px; line-height: 16px;
 	}
 	.getexpert span{ font-size: 10px; }
}
@media (max-width: 767px){
	.membershipSection{
		padding: 30px 0 30px 0;
	}
	.membershipplans{
		padding: 0px 0 30px 0;
	}
	.membershipForm label{
		font-size: 14px;
		line-height: 20px;
	}
	.planDtlsInner{
		margin: 0 0 20px 0;
	}
	.eventSection{
		padding: 30px 0 30px 0;
	}
	.eventSection .eventInner .event-list-details h4 {
	    font-size: 16px;
	    line-height: 22px;
	}
	.eventSection .eventInner .event-list-details h2 {
	    font-size: 18px;
	    line-height: 24px;
	}
	.event-list-description p {
	    font-size: 14px;
	    line-height: 21px;
	}
	.eventSection .eventInner{
		margin: 0 0 20px 0;
	}
	.eventSection .eventInner .event-list-details{
		padding: 20px 10px;
	}
	.eventSection .eventInnerSec .eventImg,
	.eventSection .eventInnerSec .event-list-details{
		display: block;
		width: 100%;
	}
	.eventSection .eventInnerSec .event-list-details{
		text-align: left;
	}
	.eventSection .eventInnerSec .event-list-details .iconDiv{
		position: absolute;
		bottom: auto;
	    top: -30px;
	    right: 0;
	}
	.eventSection .eventInnerSec .eventImg img,
	.eventSection .eventInnerSec .eventImg {
		border-radius: 10px 10px 0 0;
	}
	.eventSection .eventInner .event-list-details h2{
		height: auto;
	}
	.travelSection{
		padding: 30px 0 30px 0;
	}
	.womenImg{
		display: none;
	}
	.touchForm{
		margin: 0 0 30px 0;
	}
	.contactinfoSection {
	    padding: 10px 0 10px 0;
	}
	.contactusSection{
		padding: 10px 0 30px 0;
	}
	.touchForm h2, .availDtls h2{
		margin: 0 0 15px 0;
	}
	.profileSection{
		padding: 30px 0 30px 0;
	}


	/*START COUPON PAGE*/

	.coupon-holder{
		display: block;
		margin-bottom: 25px;
		padding: 20px;
	}

	.cupn-brnd-hldr{
		width: 100%;
		border-right: none;
	}

	a.cupncd-anchr{
		display: block;
		margin: 0 auto;
		margin-bottom: 15px;
	}

	.cupn-detls-hlder h4{
		text-align: center;
	}

	.cupn-detls-hlder h3{
		text-align: center;
	}

	.cupn-detls-hlder{
		padding: 10px 0 0 0;
	}

	.expiretxt{
		margin-left: 55px;
	}

	/*END COUPON PAGE*/

	/*START MAMBERSHIP PAGE*/

	.planDtlsInner .top {
    width: 286px;
    height: 240px;
    background-size: 100%;
}

	.membershipSection{
		padding-bottom: 0;
	}

	/*END MEMBERSHIP PAGE*/

	/*START DESTINATION PAGE*/

	.listing_panel{
		padding: 10px 0px;
	}

	.travelContent .iconlist li{
		width: 20.5%;
	}

	.travelOuterSec .travelContent{
		border-bottom: 1px solid #ddd;
	}

	/*END DESTINATION PAGE*/

	/*START ABOUT PAGE*/

	.platformdiv{
		margin-bottom: 0;
	}

	.platformdiv h3{
		font-size: 16px;
	}

	.videodiv{
		margin-top: 0;
		margin-left: 0;
	}

	/*END ABOUT PAGE*/


	/* START FIXING 18.12.20 */


	.social{
		float: left;
		padding-top: 5px;
	}

	.__react_component_tooltip.show.place-left{
		margin-left: 105px;
		left: -115px !important;
    	top: 182px !important;
	}

	.__react_component_tooltip.show.place-left::after {
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		right: 5px;
		top: 100%;
		margin-top: -4px;
		border-left-color: #222;
		border-left-style: solid;
		border-left-width: 6px;
		transform: rotate(90deg);
		margin-top: -2px;
	}

	.bannerform{
		background: rgb(91 165 200);
	}

	.route_sec {
		padding: 50px 0 102px;
	}

	.routelist{
		padding: 0 0 0 50px;
	}

	.routelist li{
		margin-left: 20px;
		margin-bottom: 22px;
	}

	.getexpert span{ font-size: 12px; }

	.texthl .price:after{
		width: 75px;
		height: 75px;
	}

	.getexpert.mb-register{
		display: inline-block;
	}

	.getexpert.mb-register{
		position: inherit;
		display: inline-block;
		width: 153px;
	}

	/* END FIXING 18.12.20 */
	

	
}
@media (max-width: 599px){
	.travelImg{
		width: 100%;
	}
	.travelContent{
		padding: 10px 0 0 0;
		width: 100%;
	}
	.travelOuter{
		flex-direction: column;
		margin: 0 0 20px 0;
	}
	.travelOuterSec{
		margin: 0 0 20px 0;
	}
	.travelContent p{
		font-size: 14px;
	}
	.field-inline{
		flex-direction: column;
	}
	.field-group{
		width: 100%;
		margin: 0 0 10px 0;
	}
	.strengthList li a{
		font-size: 16px;
		line-height: 22px;
	}
	.field-group span{
		font-size: 16px;
		line-height: 22px;
	}
}

@media (max-width:320px){
	.getexpert.mb-register{
		width: 146px;
	}
	
}